<template>
  <div></div>
</template>

<script>
import axios from 'axios'
import router from '@/router'

export default {
  created () {
    localStorage.removeItem('token')
    delete axios.defaults.headers.common.Authorization
    router.push('/login')
  }
}
</script>
